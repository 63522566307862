import React from 'react';
import { Flex } from '../Flex';
import { useTheme } from 'emotion-theming';

export function Screen(props) {
    const theme = useTheme();

    return (
        <Flex
            width="100%"
            minHeight={`calc(100vh - ${theme.headerHeight})`}
            justify="center"
            direction="column"
        >
            {props.children}
        </Flex>
    );
}
