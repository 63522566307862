import React from 'react';
import { Flex } from 'components/Flex';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import { Anchor } from 'components/Anchor';
import { Box, BoxProps } from 'components/Box';

export function NumberedListItem({
    href,
    num,
    heading,
    children,
    ...rest
}: {
    href: string,
    num: string,
    heading: string,
    children?: React.ReactNode,
} & BoxProps) {
    return (
        <Flex mb={10} {...rest}>
            <Flex direction="row" px={{ lg: 10 }}>
                <Box
                    as="span"
                    mr={{ _: 5, full: 10 }}
                    style={{ position: 'relative', top: 6 }}
                >
                    {num}
                </Box>

                <Flex direction="column">
                    <Anchor href={href} textDecoration="underline">
                        <Heading
                            fontWeight="bold"
                            display="inline-block"
                            fontSize="paragraphTitle"
                            style={{ borderBottom: '2px solid #ddd' }}
                        >
                            {heading}
                        </Heading>
                    </Anchor>

                    {children && (
                        <Paragraph mt={2} fontSize="smallBody">
                            {children}
                        </Paragraph>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
}
