import styled from '@emotion/styled';
import { ContentWidth } from 'components/ContentWidth';
import { Heading } from 'components/Heading';
import { Layout } from 'components/Layout';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { Anchor } from 'src/components/Anchor';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Carousel } from 'src/components/Carousel';
import { Flex } from 'src/components/Flex';
import { LayoutSection } from 'src/components/LayoutSection';
import { NumberedListItem } from 'src/components/NumberedListItem';
import { Paragraph } from 'src/components/Paragraph';
import { Screen } from 'src/components/Screen';
import { SmallSubtitle } from 'src/components/SmallSubtitle';
import { ThemeProvider } from 'src/components/ThemeProvider';
import { useWindowResize } from 'src/helpers/useWindowResize';
import { Contact } from 'src/homepage/Contact';
import 'swiper/swiper-bundle.css';

const Section = ({ num, title, children, isLast }: any) => (
    <Flex
        flexBasis={{ _: 'auto', lg: 280 }}
        mb={{ _: isLast ? 0 : 20, lg: 0 }}
        maxWidth="300px"
        flexGrow={0}
        flexShrink={1}
        paddingX={{ _: 0, lg: 16 }}
        direction="column"
    >
        <Box mb={10} position="relative">
            {/* <Img
                style={{
                    width: '80px',
                    position: 'absolute',
                    top: -15,
                    left: -35,
                }}
                fluid={image.childImageSharp.fluid}
            /> */}

            <Box
                as="span"
                fontSize="heroHeading"
                fontWeight="bold"
                color="boldText"
                position="relative"
                zIndex={2}
            >
                {num}
            </Box>
        </Box>

        <Heading mb={6} fontSize="paragraphTitle" fontWeight="bold">
            {title}
        </Heading>

        <Paragraph color="bodyText">{children}</Paragraph>
    </Flex>
);

const CompanyHero = () => (
    <Screen>
        <ContentWidth>
            <Box pl={{ lg: 64 }} my={{ _: 16, lg: 32 }}>
                <Heading maxWidth="650px" as="h1" fontSize="sectionHeading">
                    we build bold communities from the ground-up to the
                    long-established
                </Heading>

                <Flex
                    mt={{ _: 6, lg: 32 }}
                    direction={{ _: 'column', lg: 'row' }}
                >
                    <Box
                        flexShrink={0}
                        pr={32}
                        py={10}
                        borderLeft={{ lg: '2px solid' }}
                        borderLeftColor={{ lg: 'border' }}
                    >
                        <NumberedListItem
                            num="01"
                            heading="See our agency site"
                            href="https://audent.io"
                        />
                        {/* <NumberedListItem
                            num="02"
                            heading="See our strategists pitch"
                            href="https://docs.google.com/presentation/d/1sCTp2AI4ecXjvZyckDrpGgaKyL75C8HkFKdTEcVGACA"
                        /> */}
                        <NumberedListItem
                            num="03"
                            heading="See our community pitch"
                            href="https://docs.google.com/presentation/d/11zi4zLjFQn9skZqZoqqsxXZFUESCksxjtGRqmbCy3cI"
                        />
                        <NumberedListItem
                            mb={0}
                            num="04"
                            heading="Join our newsletter"
                            href="https://l.audent.io/community-newsletter-signup"
                        />
                    </Box>

                    <Box mt={6}>
                        <SmallSubtitle mb={10}>The TL;DR</SmallSubtitle>
                        <Paragraph maxWidth="520px" fontSize="paragraphTitle">
                            Established in 2013, we are a down-to-earth team of
                            thinkers and makers. Just people that work hard and
                            love what they do. Audentio brings people &
                            communities together with powerful digital
                            experiences.
                        </Paragraph>
                    </Box>
                </Flex>
            </Box>
        </ContentWidth>
    </Screen>
);

const WhatDoYouDo = () => (
    <ThemeProvider defaultMode="dark">
        <ContentWidth>
            <LayoutSection hasPadding>
                <Heading
                    textAlign="center"
                    maxWidth="85%"
                    mx="auto"
                    mb={20}
                    as="h3"
                    fontSize="subsectionHeading"
                >
                    ok, that’s cool. what do you do for communities?
                </Heading>

                <Flex
                    direction={{ _: 'column', lg: 'row' }}
                    justify="center"
                    align="flex-start"
                >
                    <Section title="strategy" num="01">
                        Help build and enhance your community with
                        recommendations based on an understanding of your
                        current state and goals.
                    </Section>

                    <Section title="creative" num="02">
                        Design the visual components and user journey to convey
                        the right message and generate results in your
                        community.
                    </Section>

                    <Section title="tech" num="03" isLast>
                        Develop the tools your community needs and relieve you
                        from managing the technical details of your platform.
                    </Section>
                </Flex>
            </LayoutSection>
        </ContentWidth>
    </ThemeProvider>
);

const StyledIndustryCard = styled(Flex)`
    span {
        // needed for Img
        display: block;
    }
`;
const IndustryCard = ({ num, title, description, href, img }) => (
    <StyledIndustryCard
        as={Anchor}
        display="block"
        href={href}
        maxWidth="320px"
    >
        <Box as="span" display="block" height="240px" backgroundColor="#ddd">
            <Img
                Tag="span"
                style={{
                    height: '100%',
                    maxWidth: '100%',
                    objectFit: 'cover',
                }}
                fluid={img}
            />
        </Box>
        <Box
            p={10}
            backgroundColor="secondaryBackground"
            style={{ minHeight: '138px' }}
        >
            <Box display="block" as="span" color="bodyText">
                {num}
            </Box>

            <Heading
                mt={4}
                as="h6"
                color="boldText"
                fontSize="subtitle"
                fontWeight="bold"
            >
                {title}
            </Heading>

            <Box lineHeight="base" as="span" color="bodyText">
                {description}
            </Box>
        </Box>
    </StyledIndustryCard>
);
const StartupToLeaders = ({ data }) => {
    const { windowWidth } = useWindowResize();

    let slidesPerView = 1;
    let spaceBetween = 15;
    let slideWidth = 320;

    if (windowWidth > 850) {
        slidesPerView = 2;
        spaceBetween = 30;
    }

    if (windowWidth > 1200) {
        slidesPerView = 3;
    }

    return (
        <LayoutSection overflow="hidden">
            <ContentWidth>
                <Flex width="100%" direction={{ _: 'column', xl: 'row' }}>
                    <Heading fontSize="subsectionHeading">
                        from startups to industry leaders
                    </Heading>

                    <Paragraph
                        pl={{ xl: 32 }}
                        fontSize="paragraphTitle"
                        maxWidth="520px"
                    >
                        Our years of experience across industries runs deep
                        while we maintain a constant lookout for what’s next.
                    </Paragraph>
                </Flex>

                <Box
                    height="1px"
                    backgroundColor="border"
                    width="120px"
                    my={12}
                />

                <Carousel
                    slidesPerView="auto"
                    slideWidth={slideWidth}
                    spaceBetween={spaceBetween}
                    style={{
                        overflow: 'visible',
                        width: '320px',
                        marginLeft: '0',
                    }}
                    slides={[
                        <IndustryCard
                            href="https://www.audent.io/case-study/gaming/full"
                            num="01"
                            title="gaming"
                            description="combining competition, connections, and technology for the ultimate community experience"
                            img={data.industryGaming.childImageSharp.fluid}
                        />,
                        <IndustryCard
                            href="https://www.audent.io/case-study/tech/full"
                            num="02"
                            title="tech"
                            description="strategy, design, and development for industry leaders"
                            img={data.industryTech.childImageSharp.fluid}
                        />,
                        <IndustryCard
                            href="https://www.audent.io/case-study/lifestyle/full"
                            num="03"
                            title="lifestyle"
                            description="connecting passions with passionate people"
                            img={data.industryLifestyle.childImageSharp.fluid}
                        />,
                        <IndustryCard
                            href="https://www.audent.io/case-study/government/full"
                            num="04"
                            title="government"
                            description="serving constituents with powerful technology"
                            img={data.industryGovt.childImageSharp.fluid}
                        />,
                        <IndustryCard
                            href="https://www.audent.io/case-study/sports/full"
                            num="05"
                            title="sports"
                            description="winning strategies for bringing sports online"
                            img={data.industrySports.childImageSharp.fluid}
                        />,
                        <IndustryCard
                            href="https://www.audent.io/case-study/outdoors/full"
                            num="06"
                            title="outdoors"
                            description="building journeys that bring adventurers together"
                            img={data.industryOutdoors.childImageSharp.fluid}
                        />,
                    ]}
                />
            </ContentWidth>
        </LayoutSection>
    );
};

const StyledCaseStudyCard = styled(Flex)`
    span {
        // needed for Img
        display: block;
    }
`;
const CaseStudyCard = ({ img, href, title, description }) => (
    <StyledCaseStudyCard
        as={Anchor}
        href={href}
        flexGrow={1}
        direction="column"
        mx={{ _: 4, xl: 10 }}
        mb={{ _: 12, lg: 0 }}
    >
        <Box maxWidth={{ _: '100%', sm: '80%', md: '300px' }}>
            <Img Tag="span" style={{ height: '100%' }} fluid={img} />
        </Box>
        <Heading as="strong" mt={6} fontSize="paragraphTitle" fontWeight="bold">
            {title}
        </Heading>
        <Paragraph
            color="bodyText"
            maxWidth={{ _: '100%', sm: '80%', md: '300px' }}
        >
            {description}
        </Paragraph>
    </StyledCaseStudyCard>
);

const CaseStudies = ({ data }) => (
    <LayoutSection>
        <ContentWidth>
            <Flex
                width="100%"
                direction={{ _: 'column', md: 'row' }}
                justify={{ md: 'space-between' }}
            >
                <Heading fontSize="subsectionHeading">
                    some of our case studies
                </Heading>

                <Box>
                    <Button href="https://www.audent.io/case-studies">
                        See case studies
                    </Button>
                </Box>
            </Flex>

            <Box height="1px" backgroundColor="border" width="120px" my={12} />

            <Flex
                direction={{ _: 'column', md: 'row' }}
                mx={{ _: -4, xl: -10 }}
            >
                <CaseStudyCard
                    title="Paradox Interactive"
                    description="Keeping brand loyalty for a community at the top of its game"
                    img={data.paradox.childImageSharp.fluid}
                    href="https://www.audent.io/case-study/paradox/full"
                />
                <CaseStudyCard
                    title="Playaction Pools"
                    description="A sports pool platform with a new edge for running pools"
                    img={data.playaction.childImageSharp.fluid}
                    href="https://www.audent.io/case-study/playaction/full"
                />
                <CaseStudyCard
                    title="XDA Developers"
                    description="Modernize and streamline one of tech’s largest communities"
                    img={data.xda.childImageSharp.fluid}
                    href="https://www.audent.io/case-study/xda/full"
                />
            </Flex>
        </ContentWidth>
    </LayoutSection>
);

const Company = ({ data }) => (
    <Layout
        pageTitle="Company"
        siteDescription="Audentio builds bold communities from the ground-up to the long-established, bringing people & communities together with powerful digital experiences."
    >
        <CompanyHero />
        <WhatDoYouDo />
        <StartupToLeaders data={data} />
        <CaseStudies data={data} />
        <Contact />
    </Layout>
);

export default Company;

export const query = graphql`
    query CompanyQuery {
        paradox: file(relativePath: { eq: "images/company/paradox.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        playaction: file(
            relativePath: { eq: "images/company/playaction.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        xda: file(relativePath: { eq: "images/company/xda.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        industryGaming: file(
            relativePath: { eq: "images/company/gaming-bg.jpg" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        industrySports: file(
            relativePath: { eq: "images/company/sports-bg.jpg" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        industryGovt: file(
            relativePath: { eq: "images/company/government-bg.jpg" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        industryLifestyle: file(
            relativePath: { eq: "images/company/lifestyle-bg.jpg" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        industryTech: file(relativePath: { eq: "images/company/tech-bg.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        industryOutdoors: file(
            relativePath: { eq: "images/company/outdoors-bg.jpg" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
