import { LayoutSection } from 'components/LayoutSection';
import React from 'react';
import { ContactForm } from 'src/components/ContactForm';
import { ContentWidth } from 'src/components/ContentWidth';
import { ThemeProvider } from 'src/components/ThemeProvider';

export function Contact(props) {
    return (
        <ThemeProvider defaultMode="dark">
            <LayoutSection mb={0} hasPadding>
                <ContentWidth>
                    <ContactForm source="ac_contact_homepage" />
                </ContentWidth>
            </LayoutSection>
        </ThemeProvider>
    );
}
